/* eslint-disable max-lines */
import
{
  APIService, unwrapResponse
} from "./api.service";
import
{
  ErrorHandlerCreator, ResponseErrorHandler
} from "./response-error-handler.service";
import
{
  Observable, catchError
} from "rxjs";
import
{
  Employee, CYAEmployeeUpdate, PartialData
} from "../models";
import { Injectable } from "@angular/core";
import { EmployeeSearch } from "../models/employee-search";
import { HttpParams } from "@angular/common/http";
import { CONSTANTS } from "../constants";
import
{
  Option, OptionsPartial, SearchParameter
} from "shared-lib";

@Injectable({
  providedIn: "root"
})
export class EmployeeService
{
  static readonly employeeUrl = "Employee";
  static readonly employeePlacementUrl = "EmployeePlacement";


  private handleError: ErrorHandlerCreator;

  constructor(protected readonly api: APIService,
    readonly responseErrorHandler: ResponseErrorHandler
  )
  {
    this.handleError = responseErrorHandler.createErrorHandler("Employee");
  }

  getEmployees(skip: number, take: number)
    : Observable<PartialData<Employee> | null>
  {
    return this.api.get<PartialData<Employee>>(
      `${EmployeeService.employeeUrl}?skip=${skip}&take=${take}`
    ).pipe(
      unwrapResponse(),
      catchError(this.handleError("getEmployee", null))
    );
  }

  getEmployeesByStudent(studentId:string, skip: number, take: number)
    : Observable<PartialData<Employee> | null>
  {
    let params = new HttpParams();

    params = params.set("studentId", studentId).set("skip", skip).set("take", take);
    const options = { params: params };

    return this.api.get<PartialData<Employee>>(
      `${EmployeeService.employeePlacementUrl}/GetEmployeePlacementsByStudent`,options
    ).pipe(
      unwrapResponse(),
      catchError(this.handleError("GetEmployeePlacementsByStudent", null))
    );
  }

  SearchEmployee(employeeSearch: EmployeeSearch,
    skip: number, take: number)
    : Observable<PartialData<Employee> | null>
  {
    let params = new HttpParams();

    params = params.set("skip", skip).set("take", take);

    if (employeeSearch)
    {
      for (const key of Object.keys(employeeSearch))
      {
        params = params.set(key,
          employeeSearch[key as keyof EmployeeSearch]);
      }
    }

    const options = { params: params };


    return this.api.get<PartialData<Employee>>(
      `${EmployeeService.employeeUrl}/Search`, options
    ).pipe(
      unwrapResponse(),
      catchError(this.handleError("SearchEmployee", null))
    );
  }

  getEmployeeById(id: string): Observable<Employee | null>
  {
    return this.api.get<Employee>(`${EmployeeService.employeeUrl}/${id}`).pipe(
      unwrapResponse(),
      catchError(this.handleError("getEmployeeById", null))
    );
  }

  getEmployeesByNamed(name: string): Observable<Employee[]>
  {
    const term = name.trim();

    // Add safe, employeeUrl encoded search parameter if there is a search term
    const options = term
      ? { params: new HttpParams().set("name", term) } : {};

    return this.api.get<Employee[]>(`${EmployeeService.employeeUrl}/SearchByName`, options).pipe(
      unwrapResponse(),
      catchError(this.handleError("getEmployeesByNamed", new Array<Employee>()))
    );
  }


  getEmployeeUpdate(Id: string, isWaiting?:boolean): Observable<CYAEmployeeUpdate>
  {
    return this.api.get<CYAEmployeeUpdate>(
      EmployeeService.employeeUrl + "/GetUpdateEmployee?id=" + Id+"&isWaiting="+(isWaiting? "true": "false")
    ).pipe(
      unwrapResponse(),
      catchError(this.handleError("getEmployeeForUpdate"))
    );
  }

  getEmployeeId(): Observable<string | null>
  {
    return this.api.get<string>(EmployeeService.employeeUrl + "/GetEmployeeId").pipe(
      unwrapResponse(),
      catchError(this.handleError("GetEmployeeId"))
    );
  }

  updateEmployee(EmployeeUpdate: CYAEmployeeUpdate, isWaiting: boolean): Observable<boolean | null>
  {
    return this.api.post<boolean | null>(EmployeeService.employeeUrl + "/UpdateEmployee?isWaiting=" + isWaiting, EmployeeUpdate).pipe(
      unwrapResponse(),
      catchError(this.handleError("addEmployeeEmployees", false))
    );
  }

}